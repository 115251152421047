
import { mapActions } from 'vuex';

import { LANDING_PAGE_IDS, QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  components: {
    LandingPagesLoseWeight: () => import('@/components/landing-pages/lose-weight/lose-weight'),
  },
  head() {
    return {
      title: this.$t('landingPage.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('landingPage.meta.description'),
        },
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    };
  },

  created() {
    this.setQuizVariantAction(QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY);
    this.setLandingPageIdAction(LANDING_PAGE_IDS.BODY_NUTRITION);
  },
  mounted() {
    this.landingPageGtmHandler();
  },
  methods: {
    ...mapActions('quiz', ['setQuizVariantAction', 'setLandingPageIdAction']),
  },
};
